<template>
  <div>
    <!--      Forms-->
    <div class="max-w-3xl mx-auto min-h-screen">
      <div class="mt-10 pb-10">
        <Form
          v-for="(step, index) in step_meta"
          v-if="index === $store.getters.show_next_step"
          :id="index"
          :key="index"
          :demo-form="false"
          :forms="step[0].fields"
          :numberOfSteps="step_meta.length - 1"
          :step="step"
          classes="shadow rounded-md"
          form-type="IB-individual"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "@/views/register/Form";
import store from "@/store";

export default {
  name: "IndividualForms",
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  components: {
    Form,
  },
  computed: {
    step_meta() {
      const t = this.$t.bind(this);
      let fields = [
        [
          {
            kind: "title",
            type: "title",
            title: t("personal-details"),
            fields: [
              {
                field: "title",
                key: "title",
                label: t("Title"),
                placeholder: "Title",
                kind: "select",
                type: "select",
                needed: true,
                fieldId: "title",
                choices: [
                  { value: 1, display_name: t("Mr") },
                  { value: 2, display_name: t("Ms") },
                  {
                    value: 3,
                    display_name: t("Mrs"),
                  },
                  { value: 4, display_name: t("Miss") },
                  { value: 5, display_name: t("Dr") },
                  {
                    value: 6,
                    display_name: t("Other"),
                  },
                ],
              },
              {
                field: "first_name",
                key: "first_name",
                label: t("first-name-20db0bfeecd8fe60533206a2b5e9891a"),
                placeholder: t("first-name-20db0bfeecd8fe60533206a2b5e9891a"),
                kind: "text",
                type: "text",
                needed: true,
                half: true,
                fieldId: "fname",
              },
              {
                field: "last_name",
                key: "last_name",
                label: t("last-name-8d3f5eff9c40ee315d452392bed5309b"),
                placeholder: t("last-name-8d3f5eff9c40ee315d452392bed5309b"),
                kind: "text",
                type: "text",
                needed: true,
                half: true,
                fieldId: "lname",
              },
              {
                field: "email",
                key: "email",
                label: t("Email"),
                placeholder: t("Email"),
                kind: "email",
                type: "email",
                needed: true,
                fieldId: "email",
              },
              {
                field: "password",
                key: "password",
                label: t("please-choose-a-password"),
                placeholder: t("please-choose-a-password"),
                kind: "password",
                type: "password",
                icon: "icon-password",
                needed: true,
                fieldId: "password",
              },
              {
                field: "password_confirmation",
                key: "password_confirmation",
                label: t("password-confirmation"),
                placeholder: t("enter-password-again-to-confirm"),
                kind: "password",
                type: "password",
                icon: "icon-password",
                needed: true,
                fieldId: "pconfirmation",
              },
              {
                field: "date_of_birth",
                key: "date_of_birth",
                label: t("date-of-birth"),
                placeholder: "DD / MM / YYYY",
                kind: "date_of_birth",
                type: "text",
                needed: true,
                fieldId: "dobirth",
              },
              {
                field: "skype",
                key: "skype",
                label: t("skype-id-optional"),
                placeholder: t("skype-id"),
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "skype",
              },
              {
                field: "telephone",
                key: "telephone",
                label: t("phone-number-1f8261d17452a959e013666c5df45e07"),
                placeholder: t("phone-number-1f8261d17452a959e013666c5df45e07"),
                kind: "telephone",
                type: "telephone",
                needed: true,
                fieldId: "phone",
              },
              {
                field: "addr_country_ib",
                key: "addr_country",
                label: t("Country"),
                placeholder: t("country-name"),
                kind: "select",
                type: "select",
                needed: true,
                country: true,
                fieldId: "country",
                choices: store.getters.get_countries,
              },
              {
                field: "addr_zip",
                key: "addr_zip",
                label: "Postcode",
                placeholder: "Postcode",
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "azip",
              },
              {
                field: "addr_street",
                key: "addr_street",
                label: t("address-line-1"),
                placeholder: t("address-line-1"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "addr1",
              },
              {
                field: "addr_line_2",
                key: "addr_line_2",
                label: t("address-line-2"),
                placeholder: t("address-line-2"),
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "addr2",
              },
              {
                field: "addr_city",
                key: "addr_city",
                label: t("city-or-town"),
                placeholder: t("city-or-town"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "city",
              },
              {
                field: "target_region",
                key: "target_region",
                label: t("what-geographic-region-you-will-be-mainly-targeting"),
                placeholder: t(
                  "what-geographic-region-you-will-be-mainly-targeting"
                ),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "tregion",
              },
              {
                field: "currency",
                key: "currency",
                label: t("Currency"),
                placeholder: t("Currency"),
                kind: "select",
                type: "select",
                needed: true,
                fieldId: "currency",
                choices: [
                  { value: "GBP", display_name: "GBP" },
                  { value: "USD", display_name: "USD" },
                  {
                    value: "EUR",
                    display_name: "EUR",
                  },
                ],
              },
              {
                field: "terms_and_conditions",
                key: "terms_and_conditions",
                label:
                  "I have read, understood and accepted the Privacy Policy, Risk Disclosures and Terms & Conditions",
                kind: "checkbox",
                type: "checkbox",
                needed: true,
                fieldId: "tconditions",
              },
            ],
          },
        ],
      ];
      if (
        this.whitelabel === "EagleGlobalMarkets" ||
        this.whitelabel === "TradeCoreUK" ||
        this.whitelabel === "GCCBrokers"
      ) {
        let currencyKey = fields[0][0].fields.findIndex(
          (x) => x.key === "currency"
        );
        let currency2 = {
          field: "currency",
          key: "currency",
          label: t("Currency"),
          placeholder: t("Currency"),
          kind: "select",
          type: "select",
          needed: true,
          fieldId: "currency",
          choices: [{ value: "USD", display_name: "USD" }],
        };
        fields[0][0].fields[currencyKey] = currency2;
      }
      if (this.whitelabel === "Blackstone") {
        let confPass = fields[0][0].fields.findIndex(
          (x) => x.key === "currency"
        );
        let pass2 = {
          field: "currency",
          key: "currency",
          label: t("Currency"),
          placeholder: t("Currency"),
          kind: "select",
          type: "select",
          needed: true,
          fieldId: "currency",
          choices: [
            { value: "USD", display_name: "USD" },
            { value: "ZAR", display_name: "ZAR" },
          ],
        };
        fields[0][0].fields[confPass] = pass2;
      }
      if (this.whitelabel === "OneTradingMarkets") {
        let skypeField = fields[0][0].fields.findIndex(
          (x) => x.key === "skype"
        );
        fields[0][0].fields.splice(skypeField, 1);

        let postcodeField = fields[0][0].fields.findIndex(
          (x) => x.key === "addr_zip"
        );
        fields[0][0].fields.splice(postcodeField, 1);

        let currencyKey = fields[0][0].fields.findIndex(
          (x) => x.key === "currency"
        );
        let currency2 = {
          field: "currency",
          key: "currency",
          label: t("Currency"),
          placeholder: t("Currency"),
          kind: "select",
          type: "select",
          needed: true,
          fieldId: "currency",
          choices: [{ value: "USD", display_name: "USD" }],
        };
        fields[0][0].fields[currencyKey] = currency2;

        let termAndCon = fields[0][0].fields.findIndex(
          (x) => x.key === "terms_and_conditions"
        );
        let termsAndCondition2 = {
          field: "terms_and_conditions",
          key: "terms_and_conditions",
          label:
            'I have read, understood and accepted the <a href="https://onetradingmarkets.com/privacy-policy-2/" target="_blank">Privacy Policy</a>, Risk Disclosures and <a href="https://onetradingmarkets.com/terms-conditions/" target="_blank">Terms & Conditions</a>',
          kind: "checkbox",
          type: "checkbox",
          needed: true,
          fieldId: "tconditions",
        };
        fields[0][0].fields[termAndCon] = termsAndCondition2;
      }
      if (this.whitelabel === "GCCBrokers") {
        let termAndCon = fields[0][0].fields.findIndex(
          (x) => x.key === "terms_and_conditions"
        );
        let termsAndCondition2 = {
          field: "terms_and_conditions",
          key: "terms_and_conditions",
          label: t(
            "i-have-read-understood-and-accepted-the-privacy-policy-risk-disclosures-and-terms-and-conditions"
          ),
          kind: "checkbox",
          type: "checkbox",
          needed: true,
          fieldId: "tconditions",
        };
        fields[0][0].fields[termAndCon] = termsAndCondition2;
      }
      return fields;
    },
  },
  beforeCreate() {
    if (!localStorage.getItem("step")) {
      localStorage.setItem("step", "0");
    }
  },
  created() {
    this.$store.dispatch("countries");
  },
};
</script>